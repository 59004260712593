import React, { createContext, useContext, useState } from 'react';
import type { Message } from '../../types/chatbot';

interface NovaContextType {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  context: ChatContext;
  updateContext: (updates: Partial<ChatContext>) => void;
}

interface ChatContext {
  history: Message[];
  preferences: Record<string, any>;
  lastInteraction: Date;
  sessionId: string;
}

const NovaContext = createContext<NovaContextType | undefined>(undefined);

export function NovaProvider({ children }: { children: React.ReactNode }) {
  const [messages, setMessages] = useState<Message[]>([{
    id: '1',
    type: 'assistant',
    content: `👋 Hi! I'm Nova, your AI assistant powered by GPT-4.

I can help you with:
• Find the perfect AI tools for your needs (500+ tools)
• Get exclusive launch discounts (60% off today)
• Set up your AI workspace in minutes
• Access technical documentation & guides
• Get personalized recommendations

What would you like help with?`,
    timestamp: new Date()
  }]);
  const [context, setContext] = useState<ChatContext>({
    history: [],
    preferences: {},
    lastInteraction: new Date(),
    sessionId: Math.random().toString(36).substring(2)
  });

  const updateContext = (updates: Partial<ChatContext>) => {
    setContext(prev => ({ ...prev, ...updates }));
  };

  return (
    <NovaContext.Provider value={{ messages, setMessages, context, updateContext }}>
      {children}
    </NovaContext.Provider>
  );
}

export function useNova() {
  const context = useContext(NovaContext);
  if (!context) {
    console.error('NovaProvider not found - falling back to default values');
    return {
      messages: [],
      setMessages: () => {},
      context: {
        history: [],
        preferences: {},
        lastInteraction: new Date(),
        sessionId: Math.random().toString(36).substring(2)
      },
      updateContext: () => {}
    };
  }
  return context;
}