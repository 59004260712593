import React, { useState, useEffect, useRef } from 'react';
import { Send, Bot, User, Sparkles, X, Maximize2, Minimize2, Code, Image, FileText, Zap, Brain } from 'lucide-react';
import Button from '../ui/Button';
import type { Message } from '../../types/chatbot';
import NovaMessage from './NovaMessage';
import NovaInput from './NovaInput';
import { useNova } from './NovaContext';

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;
const ACTIVE_USERS = Math.floor(Math.random() * 10) + 10; // Random number between 10-20

const suggestions = [
  { text: "What can you help me with?", icon: Brain },
  { text: "Show me pricing & plans", icon: FileText },
  { text: "How do I get started?", icon: Zap },
  { text: "What makes GALAX different?", icon: Sparkles }
];

const capabilities = [
  { icon: Brain, label: 'GPT-4 Powered' },
  { icon: Code, label: 'Code Understanding' },
  { icon: Image, label: 'Image Analysis' },
  { icon: Zap, label: 'Real-time Help' },
  { icon: Sparkles, label: 'Custom AI' }
];

export default function NovaChat() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [showSocialProof, setShowSocialProof] = useState(true);
  const [selectedCapability, setSelectedCapability] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const { messages, setMessages, context, updateContext } = useNova();
  
  // Ensure messages is always an array
  const safeMessages = messages || [];
  const lastMessage = safeMessages.length > 0 ? safeMessages[safeMessages.length - 1] : null;
  const hasErrorState = lastMessage?.type === 'error' || false;

  // Focus input when chat opens
  useEffect(() => {
    if (isOpen && !isMinimized) {
      inputRef.current?.focus();
    }
  }, [isOpen, isMinimized]);

  useEffect(() => {
    // Show social proof for 10 seconds when chat opens
    if (isOpen) {
      const timer = setTimeout(() => {
        setShowSocialProof(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleRetry = async () => {
    if (retryCount >= MAX_RETRIES) {
      setError('Maximum retry attempts reached. Please try again later.');
      return;
    }

    setRetryCount(prev => prev + 1);
    await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
    await handleSubmit({ preventDefault: () => {} } as React.FormEvent);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input?.trim() || isTyping) return;
    
    // Ensure messages array exists
    if (!Array.isArray(messages)) {
      console.error('Messages is not an array');
      setMessages([]);
      return;
    }

    setIsTyping(true);
    setError(null);
    
    // Update context with latest interaction
    updateContext({
      lastInteraction: new Date(),
      history: [...context.history, { role: 'user', content: input }]
    });

    // Cancel any existing request
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();

    const userMessage: Message = {
      id: Date.now().toString(),
      type: 'user',
      content: input,
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');

    try {
      const response = await fetch('/.netlify/functions/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Session-ID': context?.sessionId || Math.random().toString(36).substring(2)
        },
        body: JSON.stringify({ 
          message: input,
          context: Array.isArray(context?.history) ? context.history.slice(-5) : [],
          preferences: context?.preferences ?? {}
        }),
        signal: abortControllerRef.current.signal
      });

      if (!response.ok) {
        throw new Error('Failed to get response from chat service');
      }

      const data = await response.json();
      
      if (!data || !data.message) {
        throw new Error('Invalid response format');
      }
      
      const botMessage: Message = {
        id: (Date.now() + 1).toString(),
        type: 'assistant',
        content: data.message,
        timestamp: new Date()
      };

      setMessages(prev => [...prev, botMessage]);
      setRetryCount(0); // Reset retry count on success
    } catch (error) {
      if (error.name === 'AbortError') {
        return; // Ignore aborted requests
      }

      console.error('Nova chat error:', error);
      
      setError('Failed to get response. Please try again.');
      
      if (retryCount < MAX_RETRIES) {
        handleRetry();
      }
    } finally {
      setIsTyping(false);
    }
  };

  if (!isOpen) {
    return (
      <div className="fixed bottom-4 right-4 z-50">
        {/* Error recovery message - safely check messages */}
        {safeMessages.length > 0 && safeMessages[safeMessages.length - 1]?.content?.includes('having trouble') && (
          <div className="absolute bottom-full right-0 mb-4 bg-yellow-50 dark:bg-yellow-900/20 text-yellow-800 dark:text-yellow-200 rounded-lg p-3 shadow-lg border border-yellow-200 dark:border-yellow-800/50 max-w-xs animate-fade-in">
            <p className="text-sm">
              Connection restored! Click to try again.
            </p>
          </div>
        )}
        {showSocialProof && (
          <div className="absolute bottom-full right-0 mb-4 bg-white dark:bg-slate-900 rounded-lg p-3 shadow-lg border border-gray-200 dark:border-gray-800 animate-fade-in">
            <div className="flex items-center space-x-2">
              <div className="flex -space-x-2">
                {[...Array(3)].map((_, i) => (
                  <img
                    key={i}
                    src={`https://i.pravatar.cc/32?img=${i + 10}`}
                    alt="User avatar"
                    className="w-6 h-6 rounded-full border-2 border-white dark:border-slate-900"
                  />
                ))}
              </div>
              <span className="text-sm text-gray-600 dark:text-gray-400">
                {ACTIVE_USERS} people viewing
              </span>
            </div>
          </div>
        )}
        <div className="relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-violet-500/20 rounded-full blur-lg opacity-0 group-hover:opacity-100 transition-all duration-300" />
          <button
          onClick={() => setIsOpen(true)}
          className="relative p-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 bg-gradient-to-br from-blue-600 via-violet-600 to-purple-600 hover:from-blue-500 hover:via-violet-500 hover:to-purple-500 group-hover:scale-110"
        >
          <div className="absolute -top-1 -right-1 w-3 h-3 bg-green-500 rounded-full">
            <div className="absolute inset-0 rounded-full animate-ping bg-green-500/50" />
          </div>
          <Bot className="w-6 h-6 text-white group-hover:scale-110 transition-transform" />
        </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 z-[100] transition-all duration-300 max-w-[90vw] group/chat" style={{ width: isMinimized ? 'auto' : '26rem' }}>
      {/* Error Message */}
      {error && (
        <div className="absolute -top-16 right-0 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-xl p-4 shadow-xl border border-red-200 dark:border-red-800/50 animate-fade-in backdrop-blur-sm">
          <p className="text-sm">{error}</p>
          {retryCount < MAX_RETRIES && (
            <button
              onClick={handleRetry}
              className="text-xs text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 mt-2 px-3 py-1 rounded-lg bg-blue-50 dark:bg-blue-900/30 transition-colors"
            >
              Retry
            </button>
          )}
        </div>
      )}
      {isMinimized ? (
        <button
          onClick={() => setIsMinimized(false)}
          className="p-4 bg-gradient-to-r from-blue-600 to-violet-600 text-white rounded-full shadow-xl hover:shadow-2xl transition-all duration-300 group relative"
        >
          <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-400/20 to-violet-400/20 blur-xl group-hover:opacity-100 opacity-0 transition-opacity" />
          <div className="absolute -top-1 -right-1 w-3 h-3 bg-green-500 rounded-full">
            <div className="absolute inset-0 rounded-full animate-ping bg-green-500/50" />
          </div>
          <Bot className="w-6 h-6 group-hover:scale-110 transition-transform" />
        </button>
      ) : (
        <div className="bg-white/95 dark:bg-slate-900/95 backdrop-blur-md rounded-xl shadow-2xl border border-gray-200/50 dark:border-gray-800/50 transform transition-transform duration-300 group-hover/chat:scale-[1.02]">
          {/* Header */}
          <div className="p-4 border-b border-gray-200/50 dark:border-gray-800/50 flex items-center justify-between bg-gradient-to-br from-blue-600 via-violet-600 to-purple-600 rounded-t-xl relative overflow-hidden">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(255,255,255,0.1),rgba(255,255,255,0))]" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(255,255,255,0.1),rgba(255,255,255,0))]" />
            <div className="flex items-center text-white">
              <Bot className="w-5 h-5 mr-2" />
              <div>
                <span className="font-medium block">Nova AI Assistant</span>
                <span className="text-xs text-white/80 flex items-center">
                  <span className="w-1.5 h-1.5 bg-green-500 rounded-full mr-1.5"></span>
                  Online • Powered by GPT-4
                </span>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setIsMinimized(true)}
                className="p-2 hover:bg-white/10 rounded-lg transition-colors group/btn"
              >
                <Minimize2 className="w-4 h-4 text-white group-hover/btn:scale-110 transition-transform" />
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className="p-2 hover:bg-white/10 rounded-lg transition-colors group/btn"
              >
                <X className="w-4 h-4 text-white group-hover/btn:scale-110 transition-transform" />
              </button>
            </div>
          </div>

          {/* Capabilities */}
          <div className="px-4 py-3 border-b border-gray-200/50 dark:border-gray-800/50 bg-gray-50/50 dark:bg-slate-800/50 backdrop-blur-sm">
            <div className="flex space-x-2 overflow-x-auto pb-2">
              {capabilities.map(({ icon: Icon, label }) => (
                <button
                  key={label}
                  onClick={() => setSelectedCapability(label)}
                  className={`flex items-center px-3 py-1.5 rounded-full text-xs font-medium transition-colors ${
                    selectedCapability === label
                      ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                      : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700'
                  }`}
                >
                  <Icon className="w-3 h-3 mr-1" />
                  {label}
                </button>
              ))}
            </div>
          </div>

          {/* Messages */}
          <div className="h-[32rem] overflow-y-auto p-4 space-y-4 bg-gradient-to-br from-gray-50/50 to-white/50 dark:from-slate-950/50 dark:to-slate-900/50 backdrop-blur-sm">
            {safeMessages.map((message) => (
              <div key={message.id}>
                <NovaMessage message={message} />
              </div>
            ))}
            
            {isTyping && (
              <div className="flex items-center space-x-2 text-gray-500 dark:text-gray-400 bg-gray-50/50 dark:bg-slate-800/50 p-3 rounded-lg backdrop-blur-sm">
                <Bot className="w-4 h-4" />
                <div className="flex space-x-1">
                  <div className="w-2 h-2 bg-gray-400 dark:bg-gray-600 rounded-full animate-bounce" />
                  <div className="w-2 h-2 bg-gray-400 dark:bg-gray-600 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                  <div className="w-2 h-2 bg-gray-400 dark:bg-gray-600 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
                </div>
              </div>
            )}
            
            {/* Message Status */}
            {messages.length > 0 && messages[messages.length - 1].type === 'assistant' && (
              <div className="text-xs text-gray-500 dark:text-gray-400 text-center mt-2 opacity-75">
                Message sent • {new Date().toLocaleTimeString()}
              </div>
            )}

            <div ref={messagesEndRef} />
          </div>

          {/* Suggestions */}
          {safeMessages.length === 1 && (
            <div className="px-4 pb-4 bg-gray-50/50 dark:bg-slate-800/50 backdrop-blur-sm">
              <div className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                Quick questions to get started:
              </div>
              <div className="flex flex-wrap gap-2">
                {suggestions.map(({ text, icon: Icon }) => (
                  <button
                    key={text}
                    onClick={() => {
                      setInput(text);
                      handleSubmit({ preventDefault: () => {} } as React.FormEvent);
                    }}
                    className="flex items-center px-3 py-2 text-sm bg-white/80 dark:bg-slate-700/80 hover:bg-blue-50 dark:hover:bg-blue-900/20 rounded-lg transition-all duration-300 group hover:scale-105 border border-gray-200/50 dark:border-gray-700/50 backdrop-blur-sm"
                  >
                    <Icon className="w-4 h-4 mr-2 text-blue-500/70 group-hover:text-blue-500 transition-colors" />
                    {text}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Input */}
          <div className="p-4 border-t border-gray-200/50 dark:border-gray-800/50 bg-white/50 dark:bg-slate-900/50 backdrop-blur-sm">
            <NovaInput
              value={input}
              onChange={setInput}
              onSubmit={handleSubmit}
              disabled={isTyping}
              placeholder="Message Nova AI..."
            />
          </div>
        </div>
      )}
    </div>
  );
}