import React from 'react';
import { Bot, User } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import type { Message } from '../../types/chatbot';

interface NovaMessageProps {
  message: Message;
}

export default function NovaMessage({ message }: NovaMessageProps) {
  if (!message) return null;

  const isUser = message.type === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} group`}>
      <div className={`flex items-start max-w-[80%] ${isUser ? 'flex-row-reverse' : ''}`}>
        <div className={`w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 ${
          isUser
            ? 'bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 ml-2 group-hover:scale-110 transition-transform'
            : 'bg-gradient-to-br from-violet-50 to-blue-50 dark:from-violet-900/20 dark:to-blue-900/20 mr-2 group-hover:scale-110 transition-transform'
        }`}>
          {isUser ? (
            <User className="w-4 h-4 text-blue-600/90 dark:text-blue-400/90" />
          ) : (
            <Bot className="w-4 h-4 text-violet-600/90 dark:text-violet-400/90" />
          )}
        </div>

        <div className={`rounded-xl px-4 py-2 ${
          isUser
            ? 'bg-gradient-to-br from-blue-600 to-violet-600 text-white shadow-lg hover:shadow-xl transition-all duration-300 group-hover:scale-[1.02]'
            : message.type === 'system' 
            ? 'bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 shadow-lg hover:shadow-xl transition-all duration-300 group-hover:scale-[1.02]'
            : 'bg-white/95 dark:bg-slate-800/95 backdrop-blur-sm text-gray-900 dark:text-white shadow-lg hover:shadow-xl transition-all duration-300 group-hover:scale-[1.02] border border-gray-100/50 dark:border-gray-700/50'
        }`}>
          <ReactMarkdown
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    language={match[1]}
                    style={oneLight}
                    customStyle={{
                      margin: 0,
                      padding: '1rem',
                      borderRadius: '0.5rem',
                      background: 'rgba(255, 255, 255, 0.05)',
                      fontSize: '0.875rem'
                    }}
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              }
            }}
          >
            {message.content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
}