import React, { useRef, useEffect } from 'react';
import { Send } from 'lucide-react';
import Button from '../ui/Button';

interface NovaInputProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  disabled?: boolean;
  placeholder?: string;
}

export default function NovaInput({
  value,
  onChange,
  onSubmit,
  disabled,
  placeholder = "Type your message..."
}: NovaInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!disabled) {
      inputRef.current?.focus();
    }
  }, [disabled]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <div className="flex space-x-3">
      <input
        type="text"
        ref={inputRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className="flex-1 px-4 py-3 rounded-xl border border-gray-200/50 dark:border-gray-700/50 bg-white/95 dark:bg-slate-800/95 backdrop-blur-sm focus:ring-2 focus:ring-blue-500/50 shadow-inner transition-all duration-300 hover:border-blue-300 dark:hover:border-blue-700 focus:border-blue-500 dark:focus:border-blue-500 placeholder-gray-400 dark:placeholder-gray-500"
        disabled={disabled}
      />
      <Button
        type="submit"
        variant="primary"
        size="sm"
        icon={Send}
        disabled={!value.trim() || disabled}
        onClick={onSubmit}
        className="rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 bg-gradient-to-r from-blue-600 to-violet-600 hover:from-blue-500 hover:to-violet-500 hover:scale-105 active:scale-95"
      />
    </div>
  );
}